<template>
  <b-card
    no-body
    class="card-revenue-budget"
  >
    <b-row class="mx-0">
      <b-col
        md="12"
        class="revenue-report-wrapper"
      >
        <div class="d-sm-flex justify-content-between align-items-center mb-3">
          <h5 class="card-title mb-50 mb-sm-0">
            Team Performance
          </h5>
        </div>

        <b-table striped hover :items="teams" :fields="columns">
          <template v-slot:cell(user)="data">
            <span><strong>{{ data.item.user.name }}</strong></span>
          </template>
          <template v-slot:cell(pending)="data">
            <span>{{ data.value | currency("", 0) }} </span>
          </template>
          <template v-slot:cell(confirmed)="data">
            <span>{{ data.value | currency("", 0) }} </span>
          </template>
        </b-table>

      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BDropdown, BDropdownItem, BButton,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import {mapActions} from "vuex";

export default {
  components: {
    BDropdown,
    BDropdownItem,
    BCard,
    BButton,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      columns:[{
        key: 'user',
        label: 'Name',
        sortable: false
      },{
        key: 'pending',
        label: 'Pending (KES)',
        sortable: false
      },
        {
          key: 'confirmed',
          label: 'Confirmed (KES)',
          sortable: false
        }],
      startDate:'2020-01-01',
      endDate:new Date().toISOString().split('T')[0],
      teams:[]
    }
  },
  mounted (){
    this.getTeamPerformanceData();
  },
  methods: {
    ...mapActions(["getTeamPerformance"]),

    getTeamPerformanceData(){
      let self = this;
      this.isLoading = true;

      this.getTeamPerformance({startDate: this.startDate, endDate: this.endDate})
          .then(function (teams) {

            self.isLoading = false;

            self.teams = teams;

          })
          .catch(function (error) {

            if( typeof error.response.status != "undefined"){
              if(error.response.status == 401){
                location.replace("/login");
              }
            }

          })
    },
  }
  }
</script>
