<template>
  <div>
    <b-card
            v-if="data"
            no-body
            class="card-statistics"
    >
      <b-card-header>
        <b-card-title>Sales</b-card-title>
        <b-card-text class="font-small-2 mr-25 mb-0">
        </b-card-text>
      </b-card-header>
      <b-card-body class="statistics-body">

        <b-row>
          <b-col
                  xl="3"
                  sm="6"
                  class="mb-2 mb-xl-0"
          >
            <b-media no-body>
              <b-media-aside

                      class="mr-2"
              >
                <b-avatar
                        size="48"
                        variant="light-primary"
                >
                  <feather-icon
                          size="24"
                          icon="TrendingUpIcon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  KES. {{ salesStats.today | number('0,0') }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  Today
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col
                  xl="3"
                  sm="6"
                  class="mb-2 mb-xl-0"
          >
            <b-media no-body>
              <b-media-aside

                      class="mr-2"
              >
                <b-avatar
                        size="48"
                        variant="light-success"
                >
                  <feather-icon
                          size="24"
                          icon="DollarSignIcon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                 KES. {{ salesStats.thisWeek | number('0,0') }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  This Week
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col
                  xl="3"
                  sm="6"
                  class="mb-2 mb-xl-0"
          >
            <b-media no-body>
              <b-media-aside

                      class="mr-2"
              >
                <b-avatar
                        size="48"
                        variant="light-danger"
                >
                  <feather-icon
                          size="24"
                          icon="BoxIcon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  KES. {{ salesStats.thisMonth | number('0,0') }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  This Month
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col
                  xl="3"
                  sm="6"
                  class="mb-2 mb-xl-0"
          >
            <b-media no-body>
              <b-media-aside

                      class="mr-2"
              >
                <b-avatar
                        size="48"
                        variant="light-info"
                >
                  <feather-icon
                          size="24"
                          icon="UserIcon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                 KES. {{ salesStats.totalSales | number('0,0') }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  Total Sales
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>

</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody,
} from 'bootstrap-vue'

import { mapGetters, mapActions } from "vuex";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import * as moment from 'moment'
import Vue2Filters from 'vue2-filters'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    DatePicker,
    Vue2Filters
  },
  data() {
    return {
      dashboard_filter:{
        start_date:"2023-07-01",
        end_date:""
      },
      salesStats:{
        today:0,
        thisWeek:0,
        thisMonth:0,
        totalSales:0
      }
    }
  },
  mounted(){
    this.getSalesStatsData();
  },
  methods: {
    ...mapActions(["getSalesStats"]),
    getSalesStatsData(){
      let self = this;

      this.getSalesStats()
          .then(function (salesStats) {
            self.isLoading = false;

            self.salesStats = salesStats;

          })
          .catch(function (error) {

          })
    },
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
}
</script>
